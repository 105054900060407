import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../constants/Rights';
import {t} from '@lingui/macro'
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { formatPrice } from '../../lib/formatting';

/**
 * @fero
 */

class TaxedPriceDisplay extends React.PureComponent {
    static propTypes = {
        totalPrice: PropTypes.string,
        linePrice: PropTypes.string,
        currency: PropTypes.string,
    };

    render() {
        const {totalPrice, linePrice, currency, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const currencyChecked = currency != null ? currency : settings.default_currency;
        const netto = formatPrice(linePrice, currencyChecked);
        const brutto = formatPrice(totalPrice, currencyChecked);

        if(!settings.use_vat)
            return <h4>{netto}</h4>
        
        return <div>
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                <h4>{t`${brutto} s DPH`}</h4>
                {t`(${netto} bez DPH)`}
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <h4>{t`${netto} bez DPH`}</h4>
                {t`(${brutto} s DPH)`}
            </RightsWrapper>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(TaxedPriceDisplay);