import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import TileAttribute from '../../general/TileAttribute';
import InputTextArea from '../../general/InputTextArea';
import Price from '../../general/Price';
import RemoveShoppingList from './RemoveShoppingListItems';
import {Checkbox} from 'antd';
import {isNumberAndGreaterThan} from '../../../lib/number';
import {merge} from '../../../lib/object';
import {createFetchShoppingListEditItem} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {formatPercentage, formatUnitPrice, formatQuantity} from '../../../lib/formatting';
import {ShoppingListItems, ShoppingListItem} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import Link from '../../navigation/Link';
import { QUERY_PARAMS, ROUTES } from '../../../constants/navigation';
import InputQuantity from '../../project/InputQuantity';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../../constants/Rights';
import InputNumber from '../../general/InputNumber';
import UnitPrice from '../../general/UnitPrice';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import TaxedPriceDisplay from '../../project/TaxedPriceDisplay';

/**
 * @fero
 */

class ShoppingListTable extends React.PureComponent {
    static propTypes = {
        shoppingListItems: ShoppingListItems.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        reload: PropTypes.func.isRequired,
        setSelected: PropTypes.func.isRequired,
        setSelectedAll: PropTypes.func.isRequired,
        isEditable: PropTypes.bool.isRequired,
    };

    areAllSelected = () => {
        const {shoppingListItems, selectedItemsIds} = this.props;
        //naive testing because of performance
        return shoppingListItems.length === selectedItemsIds.length;
    };

    render() {
        const {shoppingListItems, reload, selectedItemsIds, setSelected, setSelectedAll, isEditable, 
            [SESSION_ATTRIBUTES.SETTINGS]: settings
        } = this.props;
        const useVAT = settings.use_vat;

        return <Table
            className="shopping-list-table"
            BodyRow={ShoppingListTableRow}
            BodyTile={ShoppingListTile}
            data={shoppingListItems}
            reload={reload}
            isEditable={isEditable}
            useVAT={useVAT}
            setSelected={setSelected}
            selectedItemsIds={selectedItemsIds}
            colDefs={[
                {
                    class: 'shopping-list-table-col-checkbox text-center',
                    headerCell: <div className="text-center">
                        <Checkbox
                            checked={this.areAllSelected()}
                            onChange={(e) => {
                                const selected = e != null && e.target != null && e.target.checked != null ?
                                    e.target.checked :
                                    false;
                                setSelectedAll(selected);
                            }}
                        />
                    </div>,
                },
                {
                    class: 'shopping-list-table-col-sequence',
                    headerCell: <Trans>Č.</Trans>,
                },
                {
                    class: 'shopping-list-table-col-wide',
                    headerCell: <Trans>Názov</Trans>,
                },
                {
                    headerCell: <Trans>Výrobca</Trans>,
                    class: 'shopping-list-table-col-normal',
                },
                {
                    headerCell: <Trans>Množstvo</Trans>,
                    class: 'shopping-list-table-col-normal',
                },
                {
                    headerCell: <Trans>Jedn. cena</Trans>,
                    class: 'shopping-list-table-col-normal',
                },
                {
                    headerCell: <Trans>Spolu</Trans>,
                    class: 'shopping-list-table-col-normal',
                },
                useVAT ? {
                    headerCell: <Trans>Suma s DPH</Trans>,
                    class: 'shopping-list-table-col-normal',
                } : null,
                {
                    headerCell: <Trans>Poznámka</Trans>,
                    class: 'shopping-list-table-col-wide',
                },
                {
                    class: 'shopping-list-table-col-action',
                    rightsFrom: RIGHTS.CUSTOMER,
                },
            ]}
        />;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(ShoppingListTable);


const shoppingListItemHOC = (Component) => {
    return class extends React.PureComponent {
        static propTypes = {
            data: ShoppingListItem.isRequired,
            reload: PropTypes.func.isRequired,
            setSelected: PropTypes.func.isRequired,
            selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
            isEditable: PropTypes.bool.isRequired,
            [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
        };

        editShoppingListItem = (queryParams) => {
            const {data, reload} = this.props;
            const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
            fetchHandler(createFetchShoppingListEditItem(),
                merge(
                    {id_item: data.id_item},
                    queryParams
                ),
                reload
            );
        };

        onQuantityChange = (newQuantity) => {
            const {reload} = this.props;
            if (isNumberAndGreaterThan(newQuantity, 0))
                this.editShoppingListItem({quantity: newQuantity});
            else
                reload();
        };

        onNoticeChange = (newNotice) => {
            this.editShoppingListItem({notice: newNotice});
        };

        onSequenceChange = (newSequence) => {
            const {reload} = this.props;
            if (isNumberAndGreaterThan(newSequence, 0))
                this.editShoppingListItem({sequence: newSequence});
            else
                reload();
        };

        render() {
            const {...rest} = this.props;
            return <Component
                onQuantityChange={this.onQuantityChange}
                onNoticeChange={this.onNoticeChange}
                onSequenceChange={this.onSequenceChange}
                {...rest}
            />;
        }
    }
};

const shoppingListItem = (Component) => withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(shoppingListItemHOC(Component));

class ShoppingListTableRowComponent extends React.PureComponent {
    static propTypes = {
        data: ShoppingListItem.isRequired,
        reload: PropTypes.func.isRequired,
        setSelected: PropTypes.func.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        isEditable: PropTypes.bool.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onNoticeChange: PropTypes.func.isRequired,
        onSequenceChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, reload, setSelected, selectedItemsIds, isEditable, useVAT, onQuantityChange, onNoticeChange, onSequenceChange} = this.props;
        return <tr>
            <td className="text-center">
                <Checkbox
                    onChange={(e) => {
                        const selected = e != null && e.target != null && e.target.checked != null ?
                            e.target.checked :
                            false;
                        setSelected(selected, data.id_item)
                    }}
                    checked={selectedItemsIds.includes(data.id_item)}
                />
            </td>
            <td>
                { isEditable ? 
                    <InputNumber
                        className="text-right"
                        size="small"
                        value={data.sequence}
                        onChange={onSequenceChange}
                    /> :
                    data.sequence
                }
            </td>
            <td>
                <Link
                    className="px-2 text-dark"
                    to={ROUTES.PRODUCT_DETAILS}
                    queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                    title={<Trans>Zobraziť detail produktu</Trans>}
                >
                    {data.designation}
                </Link>
            </td>
            <td>{data.manufacturer}</td>
            <td>
                { isEditable ? 
                     <InputQuantity
                        className="text-right"
                        size="small"
                        value={Number(data.quantity)}
                        onChange={onQuantityChange}
                        unit={data.quantity_units}
                    /> :
                    formatQuantity(data.quantity, data.quantity_units)
                }
            </td>
            <td className="text-right">
                <UnitPrice price={data.unit_price} units={data.quantity_units}/>
            </td>
            <td className="text-right"><Price price={data.line_price} nullOption={<Trans>na dopyt</Trans>}/></td>
            { useVAT ?
                <td className="text-right">
                    <Price price={data.total_price} nullOption={<Trans>na dopyt</Trans>}/>
                    <div>{formatPercentage(data.tax_rate)}</div>
                </td>
                : null
            }
            <td>
                { isEditable ? 
                    <InputTextArea
                        size="small"
                        value={data.notice}
                        onChange={onNoticeChange}
                    /> :
                    data.notice
                }
            </td>
            <RightsWrapper from={RIGHTS.CUSTOMER}>
                <td className="text-center">
                    <RemoveShoppingList
                        reload={reload}
                        data={data}
                    />
                </td>
            </RightsWrapper>
        </tr>;
    }
}

const ShoppingListTableRow = shoppingListItem(ShoppingListTableRowComponent);

class ShoppingListTileComponent extends React.PureComponent {
    static propTypes = {
        data: ShoppingListItem.isRequired,
        reload: PropTypes.func.isRequired,
        setSelected: PropTypes.func.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        isEditable: PropTypes.bool.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onNoticeChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, reload, setSelected, selectedItemsIds, isEditable, useVAT, onQuantityChange, onNoticeChange} = this.props;
        return <Tile className="p-0">
            <div className="flex-row-static-dynamic">
                <div className="d-flex align-items-center tile-left-part-wrapper">
                    <Checkbox
                        className="m-2"
                        onChange={(e) => {
                            const selected = e != null && e.target != null && e.target.checked != null ?
                                e.target.checked :
                                false;
                            setSelected(selected, data.id_item)
                        }}
                        checked={selectedItemsIds.includes(data.id_item)}
                    />
                </div>
                <div className="m-2">
                    <div className="flex-row-dynamic-static">
                        <div className="align-self-center">
                            <Link
                                className="text-dark"
                                to={ROUTES.PRODUCT_DETAILS}
                                queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                            >
                                <h4 className="px-2 mt-1">{data.designation + ' (' + data.manufacturer + ')'}</h4>
                            </Link>
                            <TileAttribute
                                className="mt-2"
                                title={<Trans>Množstvo</Trans>}
                                value={
                                    isEditable ?
                                        <InputQuantity
                                            className="text-right"
                                            size="small"
                                            value={Number(data.quantity)}
                                            onChange={onQuantityChange}
                                            unit={data.quantity_units}
                                        /> :
                                        formatQuantity(data.quantity, data.quantity_units)
                                }
                            />
                        </div>
                        <RightsWrapper from={RIGHTS.CUSTOMER}>
                            <RemoveShoppingList
                                buttonClassName="m-1"
                                reload={reload}
                                data={data}
                            />
                        </RightsWrapper>
                    </div>
                    <TileAttribute
                        title={<Trans>Jedn. cena</Trans>}
                        value={<UnitPrice price={data.unit_price} units={data.quantity_units}/>}
                    />
                    <TileAttribute
                        title={<Trans>Cena spolu</Trans>}
                        value={
                            <TaxedPriceDisplay
                                linePrice={data.line_price}
                                totalPrice={data.total_price}
                            />
                        }
                    />
                    {useVAT ? 
                        <TileAttribute
                            title={<Trans>Daň</Trans>}
                            value={formatPercentage(data.tax_rate)}
                        />
                        : null
                    }
                    <TileAttribute
                        title={<Trans>Poznámka</Trans>}
                        value={
                            isEditable ?
                                <InputTextArea
                                    size="small"
                                    value={data.notice}
                                    onChange={onNoticeChange}
                                /> :
                                data.notice
                        }
                    />
                </div>
            </div>
        </Tile>
    }
}

const ShoppingListTile = shoppingListItem(ShoppingListTileComponent);