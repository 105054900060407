import React from 'react';
import PropTypes from 'prop-types';
import {formatUnitPrice} from '../../lib/formatting';
import { Decimal } from '../../constants/propTypesDefinitions';
import { t } from '@lingui/macro';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * Displays price with currency in the end. Enables defining that price is special. In this case price is rendered in red.
 *
 * @fero
 */

class UnitPrice extends React.PureComponent {
    static propTypes = {
        price: Decimal,
        units: PropTypes.string,
        currency: PropTypes.string,
        isSpecial: PropTypes.bool,
        bold: PropTypes.bool,
        isInvalid: PropTypes.bool,
        nullOption: PropTypes.node,
        priceMultiplier: PropTypes.number,
        className: PropTypes.string,
        withVAT: PropTypes.bool,
    };

    static defaultProps = {
        isSpecial: false,
        bold: false,
        isInvalid: false,
        priceMultiplier: 1,
    };

    render() {
        const {price, units, isSpecial, bold, isInvalid, priceMultiplier, currency, nullOption, className, withVAT,
             [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const priceMultiplied = price != null ? Number(price) * priceMultiplier : null;
        const useVAT = withVAT != null && settings.use_vat ? withVAT : null;

        return <span className={"text-nowrap" 
            + (isSpecial ? " special-price-color" : "") 
            + (isInvalid ? " strikethrough" : "")
            + (bold ? " font-weight-bold" : "")
            + (className != null ? " " + className : "")}
        >
            { price != null ?
                formatUnitPrice(priceMultiplied, units, currency != null ? currency : settings.default_currency)
                + (useVAT != null ?
                    " " + (useVAT ? t`s DPH` : t`bez DPH`)
                    : ""
                )
                : (nullOption != null ? nullOption : t`na dopyt`)
            }
        </span>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(UnitPrice);